import React, { useState, useEffect } from 'react';
import HeaderApp from "../../components/header/header";
import SideBar from "../../components/sidebar/sidebar";
import Footer from "../../components/footer/footer";
import Skeleton from '@mui/material/Skeleton';
import axios from '../../services/api/axios/baseUrl';
import authHeader from '../../services/api/token';
import { isMe } from '../../functions/utils';
import swal from "sweetalert";

function Demande()
{
    const [demande, setDemande] = useState([]);
    const [loading, setLoading] = useState(true);

    async function getData()  {
        axios.get("/accounts/demande/all", { headers: authHeader() })
        .then(res => 
        {
            if (res.data)
            {
                setDemande(res.data)
                setLoading(false)
            }else{
                setLoading(false);
            }
        }).catch(error => {
            return {};
        })
    }

    function deleteDemande(id){
        swal({
            title: "Es-tu sûr ?",
            text: "Une fois supprimé, vous ne pourrez plus récupérer cette demande !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                const data = {
                    id: id
                }; 
                axios.post(`/accounts/demande/remove`, data, {headers: authHeader()})
                    .then(function (json) {
                        if(json.data.success === true){
                            swal("Pouf ! La demande a été supprimée avec succès !", {
                                icon: "success",
                            });
                            getData();
                        }else{
                            if(json.data.success === null)
                            {
                                swal("Pouf ! La demande a été supprimée avec succès !", {
                                    icon: "success",
                                });
                                getData();
                            }

                            if(json.data.success === false){
                                swal({ title: "Avertissement", icon: 'warning', text: "Nous n'avons pas pu supprimé votre demande. Veuillez réessayer plutard !" });
                            }

                            getData();
                        }
                }).catch(function (error) {
                    swal({ title: "Erreur", icon: 'error', text: error });
                });  
            } else {
              swal("Suppression rejétée.");
            }
        });
    }

    function approvalDemande(id){
        swal({
            title: "Es-tu sûr ?",
            text: "De vouloir valider cette demande du client",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                const data = {
                    approve: 1,
                    idt_demandes: id
                }; 
                axios.post(`/accounts/demande/approval`, data,  {headers: authHeader()})
                    .then(function (json) {
                        if(json.data.success === true){
                            swal("La demande a été validé avec succès.", {
                                icon: "success",
                            });
                            getData();
                        }else{
                            swal({ title: "Avertissement", icon: 'warning', text: "Nous n'avons pas pu validé la demande. Veuillez réessayer plutard !" });
                        }
                }).catch(function (error) {
                    swal({ title: "Erreur", icon: 'error', text: error });
                });  
            } else {
              swal("Suppression rejétée.");
            }
        });
    }

    useEffect(() => {
        getData();
    }, [])


    return( 
        <div>
            <HeaderApp/>
            <div className="clearfix"></div>
                <div className="dashboard-container">
                    <SideBar page="demande"/>

                    <div className="dashboard-content-container" data-simplebar>
                        <div className="dashboard-content-inner" >

                            <div className="dashboard-headline">
                                <h3>Demande</h3>
                                <nav id="breadcrumbs" className="dark">
                                    <ul>
                                        <li><a href="#">Dashboard</a></li>
                                        <li><a href="#">Management</a></li>
                                        <li>Demande</li>
                                    </ul>
                                </nav>
                            </div>
                    
                           
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="dashboard-box margin-top-0">

                                        <div className="headline">
                                            <h3><i className="icon-material-outline-business-center"></i>Demande {demande.data && demande.data.length > 0 ? demande.data.length  : '0' }</h3>
                                        </div>

                                        <div className="content">
                                            
                                        {  loading  ? <div className="center">
                                            <Skeleton
                                             sx={{ bgcolor: 'grey.900' }}
                                             variant="rectangular"
                                             height={118}
                                        /></div> : 
                                        <ul className="dashboard-box-list">
                                            { demande.data && demande.length > 0 ? <h1>Aucune demande</h1> : demande.data.map(item => (
                                                <li key={item.idt_demandes}>
                                                    <div className="job-listing">
                                                        
                                                        <div className="job-listing-details">
                                                            <div className="job-listing-description">
                                                                <h3 className="job-listing-title"><a href="#">{"De : "+item.user.noms}</a> <span class={"dashboard-status-button green"}>{item.approve ? "Validé" : "En attente de validation"}</span></h3>
                                                                <p>{item.description}</p>
                                                                <div className="job-listing-footer">
                                                                    <ul>
                                                                        <li><i className="icon-material-outline-date-range"></i>{item.created_at}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="buttons-to-right always-visible">
                                                        <button  className="button ripple-effect"><i className="icon-material-outline-supervisor-account"></i> Gerer les candidats</button>
                                                        {
                                                            item.approve ? '' : <button onClick={(e) => approvalDemande(item.idt_demandes)} className="button red ripple-effect"><i className="icon-material-outline-check-circle"></i>Valider</button>
                                                        }
                                                        
                                                        { isMe(item.user.idt_users) ? <a href="#" className="button gray ripple-effect ico" title="Edit" data-tippy-placement="top"><i className="icon-feather-edit"></i></a> : ""}
                                                        <button onClick={(e) => deleteDemande(item.idt_demandes)} className="button gray ripple-effect ico" title="Remove" data-tippy-placement="top"><i className="icon-feather-trash-2"></i></button>
                                                    </div>
                                                </li>
                                                ))
                                            }
                                        </ul>
                                        }
                                        </div>
                                    </div>
                                </div>

                    

                                </div>
                            <div className="dashboard-footer-spacer"></div>
                            <Footer/>
                        </div>
                    </div>

                </div>
        </div>
    );
}

export default Demande;