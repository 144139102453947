import HeaderApp from "../../components/header/header";
import SideBar from "../../components/sidebar/sidebar";
import React, { useState, useEffect } from 'react';
import { isAdmin } from "../../functions/utils";
import Footer from "../../components/footer/footer";
import Skeleton from '@mui/material/Skeleton';
import axios from '../../services/api/axios/baseUrl';
import authHeader from '../../services/api/token';
import swal from "sweetalert";

function Commentaire(){

    const [commentaire, setCommentaire] = useState([]);
    const [loading, setLoading] = useState(true);

    async function getData()  {
        axios.get("/accounts/commentaires/fetch", { headers: authHeader() })
        .then(res => 
        {
            if (res.data)
            {
                setCommentaire(res.data)
                setLoading(false)
            }else{
                setCommentaire(false);
            }
        }).catch(error => {
            return {};
        })
    }

    function deleteCommentaire(id){
        swal({
            title: "Es-tu sûr ?",
            text: "Une fois supprimé, vous ne pourrez plus récupérer ce commentaire !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                const data = {
                    id: id
                }; 
                axios.post(`/accounts/commentaires/remove`, data, {headers: authHeader()})
                    .then(function (json) {
                        if(json.data.success === true){
                            swal("Pouf ! Le commentaire a été supprimé avec succès !", {
                                icon: "success",
                            });
                            getData();
                        }else{
                            if(json.data.success === null)
                            {
                                swal("Pouf ! Le commentaire a été supprimé avec succès !", {
                                    icon: "success",
                                });
                                getData();
                            }

                            if(json.data.success === false){
                                swal({ title: "Avertissement", icon: 'warning', text: "Nous n'avons pas pu supprimé ce commentaire. Veuillez réessayer plutard !" });
                            }

                            getData();
                        }
                }).catch(function (error) {
                    swal({ title: "Erreur", icon: 'error', text: error });
                });  
            } else {
              swal("Suppression rejétée.");
            }
        });
    }


    useEffect(() => {
        getData();
    }, [])


    return(
        <div>
            <HeaderApp/>
                <div className="dashboard-container">
                    <SideBar page="commentaire"/>
                    <div className="dashboard-content-container" data-simplebar>
                        <div className="dashboard-content-inner" >

                            <div className="dashboard-headline">
                                <h3>Commentaire</h3>
                                <nav id="breadcrumbs" className="dark">
                                    <ul>
                                        <li><a href="#">Dashboard</a></li>
                                        <li><a href="#">Management</a></li>
                                        <li>Commentaire</li>
                                    </ul>
                                </nav>
                            </div>
                      
                    <div className="row">

                                <div className="col-xl-12">
                                    <div className="dashboard-box margin-top-0">

                                        <div className="headline">
                                            <h3>
                                            <i className="icon-material-outline-supervisor-account"></i> 
                                            {
                                              commentaire.data && commentaire.data.length > 0 ? commentaire.data.length  : '0'
                                            } Commentaire.s
                                            </h3>
                                        </div>
                                            
                                        {  loading  ? <div className="center">
                                            <Skeleton
                                                sx={{ bgcolor: 'grey.900' }}
                                                variant="rectangular"
                                                height={118}
                                            /></div> : 
                                            <div className="col-xl-12 col-md-12">
                                        
                                                <table className="basic-table">
                                                    
                                                    <tr>
                                                        <th>Client</th>
                                                        <th>E-mail ou Téléphone</th>
                                                        <th>Expert</th>
                                                        <th>Commentaire</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    {
                                                        commentaire.data.map(item => (

                                                        <tr key={item.idt_commentaire}>
                                                            <td data-label={item.identite}>{item.identite}</td>
                                                            <td data-label={item.phone_or_email}>{item.phone_or_email}</td>
                                                            <td data-label={item.expert.noms}>{item.expert.noms}</td>
                                                            <td data-label={item.phone}>{item.commentaire}</td>
                                                            <td>
                                                            
                                                        
                                                                    <button onClick={(e) => deleteCommentaire(item.idt_commentaire)} className="button red ripple-effect"><i className="icon-feather-trash-2"></i>Retirer</button>
                                                                    <button className="button ripple-effect"><i className="icon-material-outline-assignment"></i>Lire</button>
                                                            </td>
                                                        </tr>
                                                        ))
                                                    }

                                                </table>
                                            </div>
                                        }
                                 
                                    </div>

                                </div>

                            </div>  
                        </div>
                    </div>
                        
            </div>

        </div>
    );
}

export default Commentaire