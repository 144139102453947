// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { initializeApp } from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app"
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyCD_WAt6rrufXL3u5cZg53gjJ183UKYgIo",
    authDomain: "invitation-one.firebaseapp.com",
    projectId: "invitation-one",
    storageBucket: "invitation-one.appspot.com",
    messagingSenderId: "941800846412",
    appId: "1:941800846412:web:899fb9b0685247cb5930e9",
    measurementId: "G-X5ETJN6JJC"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;




/*</measurementId>import { getAnalytics } from "firebase/analytics";
import {storage} from 'firebase/storage';
import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyCD_WAt6rrufXL3u5cZg53gjJ183UKYgIo",
  authDomain: "invitation-one.firebaseapp.com",
  projectId: "invitation-one",
  storageBucket: "invitation-one.appspot.com",
  messagingSenderId: "941800846412",
  appId: "1:941800846412:web:899fb9b0685247cb5930e9",
  measurementId: "G-X5ETJN6JJC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
//const storage = storage();

export {storage, firebase as default}

import firebase from 'firebase/app'
import 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCD_WAt6rrufXL3u5cZg53gjJ183UKYgIo",
  authDomain: "invitation-one.firebaseapp.com",
  projectId: "invitation-one",
  storageBucket: "invitation-one.appspot.com",
  messagingSenderId: "941800846412",
  appId: "1:941800846412:web:899fb9b0685247cb5930e9",
  measurementId: "G-X5ETJN6JJC"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = firebase.storage();

export  {storage, firebase as default}*/