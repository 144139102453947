import Footer from "../../components/footer/footer";
import SideBar from "../../components/sidebar/sidebar";
import HeaderApp from "../../components/header/header";
import React, { useState, useEffect } from 'react';
import axios from "../../services/api/axios/baseUrl";
import authHeader from "../../services/api/token";
import { NavLink } from "react-router-dom";
import { isAdmin } from "../../functions/utils";
import { CircularProgress } from "@mui/material";

function Home(){

    const user = JSON.parse(localStorage.getItem('user'));
    const [page, setPage] = useState("");
    const [experts, setExperts] = useState([]);
    const [users, setUsers] = useState([]);
    const [demande, setDemande] = useState([]);
    const [notification, setNotification] = useState([]);
    const [loading, setLoading] = useState(true);

    const getExperts = () => {
        axios.get("/accounts/users/experts", { headers: authHeader() })
        .then(res => 
        {
        
            if (res.data)
            {
                setExperts(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const getNotification = () => {
        axios.get("/accounts/notification/all", { headers: authHeader() })
        .then(res => 
        {
        
            if (res.data)
            {
                setNotification(res.data);
                setLoading(false);
            }else{
                setLoading(false);
            }
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }

    const getDemande = () => {
        axios.get("/accounts/demande/all", { headers: authHeader() })
        .then(res => 
        {
        
            if (res.data)
            {
                setDemande(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }


    const getUsers = () => {
        axios.get("/accounts/users/all", { headers: authHeader() })
        .then(res => 
        {
        
            if (res.data)
            {
                setUsers(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        setPage("dashboard");
        getExperts();
        getUsers();
        getDemande();
        getNotification();
    }, [])


    return (
        <div>
              <HeaderApp/>
              <div className="clearfix"></div>
                <div className="dashboard-container">
                <SideBar
                    page={page}
                />
                <div className="dashboard-content-container" data-simplebar>
                <div className="dashboard-content-inner" >
            
                    <div className="dashboard-headline">
                        <h3>{user.noms}</h3>
                        <span>Nous sommes heureux de vous revoir!</span>

                        <nav id="breadcrumbs" className="dark">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li>Dashboard</li>
                            </ul>
                        </nav>
                    </div>
            
                    <div className="fun-facts-container">
                        <div class="fun-fact" data-fun-fact-color="#36bd78">
                            <div className="fun-fact-text">
                                <span><NavLink to="/experts/manage" >Experts</NavLink></span>
                                <h4>{ experts.data && experts.data.length > 0 ? experts.data.length  : '0' }
                                </h4>
                            </div>
                            <div className="fun-fact-icon"><i className="icon-material-outline-gavel"></i></div>
                        </div>
                        <div className="fun-fact" data-fun-fact-color="#b81b7f">
                            <div className="fun-fact-text">
                                <span>Clients(s)</span>
                                <h4>{ users.data && users.data.length > 0 ? users.data.length  : '0' }</h4>
                            </div>
                            <div className="fun-fact-icon"><i className="icon-material-outline-business-center"></i></div>
                        </div>
                        <div className="fun-fact" data-fun-fact-color="#efa80f">
                            <div className="fun-fact-text">
                                <span>Utilisateur</span>
                                <h4>1</h4>
                            </div>
                            <div className="fun-fact-icon"><i className="icon-material-outline-rate-review"></i></div>
                        </div>

                        {
                            isAdmin ? 
                            <div className="fun-fact" data-fun-fact-color="#2a41e6">
                                <div className="fun-fact-text">
                                <span><NavLink to="/demande" >Demande</NavLink></span>
                                    <h4>{ demande.data && demande.data.length > 0 ? demande.data.length  : '0' }</h4>
                                </div>
                                <div className="fun-fact-icon"><i className="icon-feather-trending-up"></i></div>
                            </div> : ''
                        }
                        
                    </div>
                    

                    <div className="row">

                        <div className="col-xl-6">
                            <div className="dashboard-box">
                                <div className="headline">
                                    <h3><i className="icon-material-baseline-notifications-none"></i> Notifications</h3>
                                    <button className="mark-as-read ripple-effect-dark" data-tippy-placement="left" title="Mark all as read">
                                            <i className="icon-feather-check-square"></i>
                                    </button>
                                </div>
                                <div className="content">
                                    <ul className="dashboard-box-list">
                                        {  
                                            loading  ? <center className="center">
                                                <CircularProgress/></center> : 
                                            <ul className="dashboard-box-list">
                                                { notification.data && notification.length > 0 ? <h1>Aucune demande</h1> : notification.data.map(item => (
                                                    <li key={item.idn}>
                                                        <span className="notification-icon"><i className="icon-material-baseline-notifications-none"></i></span>
                                                        <span className="notification-text">
                                                            <strong>{item.description}</strong> <a href="#">Voir la notification</a>
                                                        </span>
                                                        {/*<div className="buttons-to-right">
                                                            <a href="#" className="button ripple-effect ico" title="Mark as read" data-tippy-placement="left"><i class="icon-feather-check-square"></i></a>
                                                        </div>*/}
                                                    </li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        
                                        {
                                            /*
                                                <li>
                                                    <span className="notification-icon"><i className=" icon-material-outline-gavel"></i></span>
                                                    <span className="notification-text">
                                                        <strong>Gilber Allanis</strong> placed a bid on your <a href="#">iOS App Development</a> project
                                                    </span>
                                                    <div className="buttons-to-right">
                                                        <a href="#" className="button ripple-effect ico" title="Mark as read" data-tippy-placement="left"><i className="icon-feather-check-square"></i></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className="notification-icon"><i className="icon-material-outline-autorenew"></i></span>
                                                    <span className="notification-text">
                                                        Your job listing <a href="#">Full Stack Software Engineer</a> is expiring
                                                    </span>
                                                    <div className="buttons-to-right">
                                                        <a href="#" className="button ripple-effect ico" title="Mark as read" data-tippy-placement="left"><i className="icon-feather-check-square"></i></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className="notification-icon"><i className="icon-material-outline-group"></i></span>
                                                    <span className="notification-text">
                                                        <strong>Sindy Forrest</strong> applied for a job <a href="#">Full Stack Software Engineer</a>
                                                    </span>
                                                    <div className="buttons-to-right">
                                                        <a href="#" class="button ripple-effect ico" title="Mark as read" data-tippy-placement="left"><i className="icon-feather-check-square"></i></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className="notification-icon"><i className="icon-material-outline-rate-review"></i></span>
                                                    <span className="notification-text">
                                                        <strong>David Peterson</strong> left you a <span className="star-rating no-stars" data-rating="5.0"></span> rating after finishing <a href="#">Logo Design</a> task
                                                    </span>
                                                    <div className="buttons-to-right">
                                                        <a href="#" className="button ripple-effect ico" title="Mark as read" data-tippy-placement="left"><i className="icon-feather-check-square"></i></a>
                                                    </div>
                                                </li>
                                            */
                                        }
                                      
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="dashboard-box">
                                <div className="headline">
                                    <h3><i className="icon-material-outline-assignment"></i> Orders</h3>
                                </div>
                                <div className="content">
                                    <ul className="dashboard-box-list">
                                        <li>
                                            <div className="invoice-list-item">
                                            <strong>Professional Plan</strong>
                                                <ul>
                                                    <li><span className="unpaid">Unpaid</span></li>
                                                    <li>Order: #326</li>
                                                    <li>Date: 12/08/2019</li>
                                                </ul>
                                            </div>
                                            <div class="buttons-to-right">
                                                <a href="pages-checkout-page.html" className="button">Finish Payment</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="invoice-list-item">
                                            <strong>Professional Plan</strong>
                                                <ul>
                                                    <li><span className="paid">Paid</span></li>
                                                    <li>Order: #264</li>
                                                    <li>Date: 10/07/2019</li>
                                                </ul>
                                            </div>
                                            <div className="buttons-to-right">
                                                <a href="pages-invoice-template.html" className="button gray">View Invoice</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="invoice-list-item">
                                            <strong>Professional Plan</strong>
                                                <ul>
                                                    <li><span className="paid">Paid</span></li>
                                                    <li>Order: #211</li>
                                                    <li>Date: 12/06/2019</li>
                                                </ul>
                                            </div>
                                            <div className="buttons-to-right">
                                                <a href="pages-invoice-template.html" className="button gray">View Invoice</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="invoice-list-item">
                                            <strong>Professional Plan</strong>
                                                <ul>
                                                    <li><span className="paid">Paid</span></li>
                                                    <li>Order: #179</li>
                                                    <li>Date: 06/05/2019</li>
                                                </ul>
                                            </div>
                                            <div className="buttons-to-right">
                                                <a href="pages-invoice-template.html" className="button gray">View Invoice</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Footer/>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Home;