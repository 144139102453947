import RoutesMain from './router';

function App() {
  return (
      <div>
          <RoutesMain/>
      </div>
  );
}

export default App;