import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import PrivateRoute from './services/privateRoute';

import Login from './pages/auth/container/login';
import Register from './pages/auth/container/register';

import Home from './pages/home/home';
import AddExpert from './pages/experts/add_expert';
import ManageExpert from './pages/experts/manage';
import AddClient from './pages/clients/add_client';
import ListClient from './pages/clients/list_client';
import Demande from './pages/demande/demande';
import Commentaire from './pages/commentaire/commentaire';


function RoutesMain() {
    return (
        <BrowserRouter>
            <Routes>
                
                <Route path='/' element={<Login />} />
                {/*<PrivateRoute path='/dashboard' component={Home}/>*/}
                <Route path='/register' element={<Register />} />
                <Route path='/dashboard' element={<Home />} />
                <Route path='/experts/add' element={<AddExpert />} />
                <Route path='/experts/manage' element={<ManageExpert />} />
                <Route path='/clients/list' element={<ListClient />} />
                <Route path='/clients/add' element={<AddClient />} />
                <Route path='/demande' element={<Demande/>} />
                <Route path='/commentaire' element={<Commentaire/>} />

                { /* <Route path='*' element={<PageNonFound />} />
                <Route path='/corbeilleCodes' element={<CorbeilleCodes />} />
                <Route path='/politiques' element={<Politiques />} />*/}
            </Routes>
        </BrowserRouter>
    )
}
export default RoutesMain