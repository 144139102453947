import Footer from "../../components/footer/footer";
import HeaderApp from "../../components/header/header";
import SideBar from "../../components/sidebar/sidebar";
import swal from "sweetalert";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useState, useEffect } from 'react'


function AddClient(){

    const initialiseValues = { id: "", noms: "", phone: "", email: "", password:"", description: "" };
    const [formData, setFormData] = useState(initialiseValues);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [noms, setNoms] = useState('');
    const [phone, setPhone] = useState('');
    const [passRepete, setPassRepete] = useState('');
    const [password, setPassword] = useState('');
    const [rue, setRue] = useState('');
    const [ville, setVille] = useState('');
    const [commune, setCommune] = useState('');
    const [sexe, setSexe] = useState('');
    const [username, setUsername] = useState('');
    const [page, setPage] = useState("");

    useEffect(() => {
        setPage("client");
    }, [])


    const submitData = () => {
   
        if (passRepete === password) {
            setLoading(true);
            const _formData = new FormData();
            _formData.append("email", email)
            _formData.append("noms", noms)
            _formData.append("sexe", sexe)
            _formData.append("username", username)
            _formData.append("ville", ville)
            _formData.append("commune", commune)
            _formData.append("rue", rue)
            _formData.append("phone", phone)
            _formData.append("password", password)
            const requestOptions = {
                method: 'POST',
                body: _formData
            };
                 
            try {

                fetch('https://bck-expert.e-solutions-rdc.com/api/v1/accounts/register/client', requestOptions)
                .then(res => res.json())
                .then(json =>{

                    if(json.success == true){
                        setLoading(false);
                        swal({ title: "Succès", icon: 'success', text: `Vous venez de créer un nouveau compte avec succès.` });
                        setFormData(initialiseValues);
                    }else{
                        setLoading(false);
                        swal({ title: "Avertissement", icon: 'warning', text: json.message });
                    }

                });
                    
            } catch (err) {
                console.log(err);
                setLoading(false);
                swal({ title: "Avertissement", icon: 'warning', text: "Erreur de connexion au serveur." });
            }
        } else {
            alert('Vos mot de passes ne sont pas identiques.')
        }
        
    }


    return(
        <div>
            <HeaderApp/>
            <div className="dashboard-container">
            <SideBar
                page={page}
            /> 
                <div className="dashboard-content-container" data-simplebar>
                    <div className="dashboard-content-inner" >
                        
                        <div className="dashboard-headline">
                            <h3>Création client</h3>
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="#">Dashboard</a></li>
                                    <li><a href="#">Management</a></li>
                                    <li>Création client</li>
                                </ul>
                            </nav>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="dashboard-box margin-top-0">
                                    <div className="headline">
                                        <h3><i className="icon-feather-folder-plus"></i> Formulaire client</h3>
                                    </div>

                                    <div className="content with-padding padding-bottom-10">
                                        <div className="row">

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Identite</h5>
                                                    <input type="text" className="with-border" onChange={(e) => setNoms(e.target.value)}/>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Sexe</h5>
                                                    <div className="input-with-icon">
                                                        <div id="autocomplete-container">
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Sexe"
                                                                value={sexe}
                                                                onChange={(e) => setSexe(e.target.value)}
                                                                >
                                                                    <MenuItem value={"M"}>Masculin</MenuItem>
                                                                    <MenuItem value={"F"}>Feminin</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Nom d'utilisateur</h5>
                                                    <div className="input-with-icon">
                                                        <div id="autocomplete-container">
                                                            <input id="autocomplete-input" className="with-border" type="text" placeholder="Exemple : jack02" onChange={(e) => setUsername(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Ville</h5>
                                                    <div className="input-with-icon">
                                                        <div id="autocomplete-container">
                                                            <input id="autocomplete-input" className="with-border" type="text" placeholder="Exemple : Kinshasa" onChange={(e) => setVille(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Commune</h5>
                                                    <div className="input-with-icon">
                                                        <div id="autocomplete-container">
                                                            <input id="autocomplete-input" className="with-border" type="text" placeholder="Exemple : Ngaliema" onChange={(e) => setCommune(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Rue</h5>
                                                    <div className="input-with-icon">
                                                        <div id="autocomplete-container">
                                                            <input id="autocomplete-input" className="with-border" type="text" placeholder="Exemple : Sakombi" onChange={(e) => setRue(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Téléphone</h5>
                                                    <div className="input-with-icon">
                                                        <input className="with-border" type="text" placeholder="Télephone" onChange={(e) => setPhone(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="submit-field">
                                                    <h5>Adresse E-mail  <i className="help-icon" data-tippy-placement="right" title="Maximum of 10 tags"></i></h5>
        
                                                    <div className="input-with-icon">
                                                        <input className="with-border" type="text" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-4">
                                                <h5>Mot de passe</h5>
                                                <div className="submit-field">
                                                    <div className="input-with-icon-left" title="Should be at least 8 characters long" data-tippy-placement="bottom">
                                                        <i className="icon-material-outline-lock"></i>
                                                        <input type="password" className="input-text with-border" name="password" id="password-register" placeholder="Mot de passe" onChange={(e) => setPassword(e.target.value)} required/>
                                                    </div>

                                                    <div className="input-with-icon-left" title="Should be at least 8 characters long" data-tippy-placement="bottom">
                                                        <i className="icon-material-outline-lock"></i>
                                                        <input type="password" className="input-text with-border" name="password-confirme" id="password" placeholder="Confirmez-le" onChange={(e) => setPassRepete(e.target.value)} required/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12">
                             {loading ? "Execution en cours..." : <a href="#" className="button ripple-effect big margin-top-30" onClick={submitData}><i className="icon-feather-plus"></i>Créer</a>}
                            </div>

                        </div>
                        
                        <div className="dashboard-footer-spacer"></div>
                        <Footer/>
                    
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddClient