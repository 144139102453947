import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
//import FolderIcon from '@mui/icons-material/Folder';
//import DeleteIcon from '@mui/icons-material/Delete';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Slide from '@mui/material/Slide';
import axios from "../../services/api/axios/baseUrl";
import CircularIndeterminate from '../../components/circularProgress';
import authHeader from "../../services/api/token";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MySpeciality = (props) => {
  const closeModal = props.close;
  const [speciality, setSpeciality] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getMySpeciality = () => {
    axios.get(`/accounts/experts/myspeciality/id?ref_user=`+props.expert, { headers: authHeader()})
    .then(res => 
    {
    
        if (res.data)
        {
            setLoading(false);
            setSpeciality(res.data);
        }
    }).catch(error => {
        console.log(error);
        setLoading(false);
    })
  }

  React.useEffect(() => {
    getMySpeciality();
  }, [])

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={closeModal}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeModal}
              aria-label="close"
            >
              {/*<CloseIcon />*/}
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Mes Spécialités
            </Typography>
            <Button autoFocus color="inherit" onClick={closeModal}>
              Retouner
            </Button>
          </Toolbar>
        </AppBar>
        {
          loading  ? <CircularIndeterminate/> :
            <div>
              {speciality.data.map(item => (
                <div  key={item.t_job_specialite+1} >
                  <List key={item.t_job_specialite} sx={{bgcolor: 'background.yellow' }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/*<DeleteIcon />*/}
                        </IconButton>
                      }
                      button
                      
                    >
                      <ListItemAvatar>
                        <Avatar>
                          {/*<FolderIcon />*/}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.specialite.specialite} secondary={item.id_professionel} />
                    </ListItem>
                    <Divider />
                  </List>   
                </div>                      
                
                ))
              }
            </div>
        }
        
      </Dialog>
    </div>
  );
}

export default MySpeciality
