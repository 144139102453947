
function isAdmin(){
   var user =  localStorage.getItem('user');

   if(user.ref_profile === 1)
   {
        return true;
   }
   return false;
}

function isExpert(){
    var user =  localStorage.getItem('user');
 
    if(user.ref_profile === 3)
    {
        return true;
    }
    return false;
 }

function isClient(){
    var user =  localStorage.getItem('user');
 
    if(user.ref_profile === 2)
    {
        return true;
    }
    return false;
}

function isMe(id){
    var user =  localStorage.getItem('user');
 
    if(user.idt_users === id)
    {
        return true;
    }
    return false;
}

module.exports = {isAdmin, isClient, isExpert, isMe}