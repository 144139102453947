import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'
import swal from "sweetalert";


function Login(){

    const [dataForm, setDataForm] = useState({
        "username":"",
        "password":""
    });
    const [error, setError] = useState({});
    const [clic, setClic] = useState(false);

    let navigate = useNavigate();

    const onLogin = () => {
        setClic(true);
        if(dataForm.username !== "" && dataForm.password!== ""){

            const _formData = new FormData();
            _formData.append("username", dataForm.username)
            _formData.append("password", dataForm.password)
            const requestOptions = {
                method: 'POST',
                body: _formData
            };
        
            try {

                fetch('https://bck-expert.e-solutions-rdc.com/api/v1/accounts/login', requestOptions)
                .then(res => res.json())
                .then(json =>{
                    console.log(json);

                    if(json.success === false){
                        setClic(false);
                        swal({ title: "Avertissement", icon: 'warning', text: json.message });
                    }else{
                        localStorage.setItem('user', JSON.stringify(json.user));
                        localStorage.setItem('token', JSON.stringify(json.refreshToken));
                        navigate('/dashboard')
                    }
                    
                }).catch(err =>{
                    console.log(err);
                    setError(err.response);
                    setClic(false);
                    swal({ title: "Avertissement", icon: 'warning', text: "Erreur de connexion au serveur." })
                });

            } catch (err) {
                console.log(err);
                setError(err.response);
                setClic(false);
                swal({ title: "Avertissement", icon: 'warning', text: "Erreur de connexion au serveur." });
            }
        }
    }

    return (
        <div>
            <div className="clearfix"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 offset-xl-3">


                        <div className="login-register-page">

                            <div className="welcome-text">
                                <h3>Connexion</h3>
                                <h4>Nous sommes heureux de vous revoir!</h4>
                            </div>
                                

                           
                                <div className="input-with-icon-left">
                                    <i className="icon-material-baseline-mail-outline"></i>
                                    <input type="text" className="input-text with-border" name="username" id="emailaddress" 
                                    placeholder="Adresse E-mail" onChange={(e)=>setDataForm({...dataForm,"username":e.target.value})} required/>
                                </div>

                                <div className="input-with-icon-left">
                                    <i className="icon-material-outline-lock"></i>
                                    <input type="password"  className="input-text with-border" name="password" id="password"
                                     placeholder="Mot de passe" onChange={(e)=>setDataForm({...dataForm, "password": e.target.value})} required/>
                                </div>
                                <a href="#" className="forgot-password">Mot de passe oublié ?</a><br/>                          
                            
                                {
                                    clic ? <center>Connexion en cours...</center> : <button className="button full-width button-sliding-icon ripple-effect margin-top-10" form="login-form" onClick={()=>onLogin()}> Connexion<i className="icon-material-outline-arrow-right-alt"></i></button>
                                }                            

                        
                        </div>

                    </div>
                </div>
            </div>

        </div>

        
    );
}

export default Login