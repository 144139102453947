import React from 'react';
import {Route, useNavigate} from 'react-router-dom';

const token = JSON.parse(localStorage.getItem('token'));

const PrivateRoute = ({ component: Component, ...rest }) => {

  let Navigate = useNavigate();
  return(
    <Route {...rest} render={props => (
      token != null ? <Component {...props} /> : <Navigate to="/dashboard"/>
    )} />
  );
}
export default PrivateRoute;