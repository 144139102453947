import { NavLink } from "react-router-dom";
import isAdmin from "../../functions/utils";

const SideBar = (props) =>{
	const isPage = props.page;

    const  page  = isPage;
    return (
        <div className="dashboard-sidebar">
            <div className="dashboard-sidebar-inner" data-simplebar>
                <div className="dashboard-nav-container">

				<a href="#" className="dashboard-responsive-nav-trigger">
					<span className="hamburger hamburger--collapse" >
						<span className="hamburger-box">
							<span className="hamburger-inner"></span>
						</span>
					</span>
					<span className="trigger-title">Dashboard</span>
				</a>
				
	
				<div className="dashboard-nav">
					<div className="dashboard-nav-inner">

						<ul data-submenu-title="Start">
							<li className={page === "dashboard" ? "active" : ""}><NavLink to="/dashboard" ><i className="icon-material-outline-dashboard"></i> Dashboard</NavLink></li>
							{
								<li className={page === "commentaire" ? "active" : ""}><NavLink to="/commentaire"><i className="icon-material-outline-question-answer"></i>Commentaire</NavLink></li>
							}
							
							<li className={page === "demande" ? "active" : ""}><NavLink to="/demande"><i className="icon-material-outline-star-border"></i>Demande</NavLink></li>
						</ul>
						
						{
							isAdmin ? 
							<ul data-submenu-title="Management">
								<li className={page === "manage" ? "active" : ""}><a><i className="icon-material-outline-business-center"></i> Experts</a>
									<ul>
										<li><NavLink to="/experts/add" >Nouvel Experts</NavLink></li>
										<li><NavLink to="/experts/manage" >Liste Experts</NavLink></li>
									</ul>	
								</li>
								<li className={page === "client" ? "active" : ""}><a href="#"><i className="icon-material-outline-supervisor-account"></i>Clients</a>
									<ul>
										<li><a href="/clients/list">Liste Clients</a></li>
										<li><NavLink to="/clients/add" >Créer</NavLink></li>
									</ul>	
								</li>
								
							</ul> : ''
						}

						{
							isAdmin ? 
							<ul data-submenu-title="Spécialité">
								<li className={page === "speciality" ? "active" : ""}><a href="#"><i className="icon-material-outline-supervisor-speciality"></i>Spécialité</a>
									<ul>
										<li><NavLink to="/speciality/add" >Ajouter</NavLink></li>
										<li><NavLink to="/speciality/manage" >Manage Spécialité</NavLink></li>
									</ul>	
								</li>
							</ul> : ''
						}
						

						
						{
							isAdmin ? 
							<ul data-submenu-title="Utilisateur">
								<li className={page === "users" ? "active" : ""}><a href="#"><i className="icon-material-outline-supervisor-account"></i>Utilisateur</a>
									<ul>
										<li><NavLink to="/users/add" >Nouveau Profile</NavLink></li>
										<li><NavLink to="/users/manage" >Liste</NavLink></li>
									</ul>	
								</li>
							</ul> : ''
						}
						


						<ul data-submenu-title="Account">
							<li><a href="dashboard-settings.html"><i className="icon-material-outline-settings"></i>Paramètres</a></li>
							<li><a href="index-logged-out.html"><i className="icon-material-outline-power-settings-new"></i> Se déconnecter</a></li>
						</ul>
						
					</div>
				</div>
                    

                </div>
            </div>
        </div>
    );
}

export default SideBar