import Footer from "../../components/footer/footer";
import HeaderApp from "../../components/header/header";
import SideBar from "../../components/sidebar/sidebar";
import React, { useState, useEffect } from 'react';
import CircularIndeterminate from "../../components/circularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../services/api/axios/baseUrl";
import authHeader from "../../services/api/token";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import swal from "sweetalert";
import Demande from "./demande";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MySpeciality from "./my_speciality";



function ManageExpert(){

    const [experts, setExperts] = useState([]);
    const [specialites, setSpecialites] = useState([])
    const [loading, setLoading] = useState(true);
    const [speLoading, setSpeloading] = useState(true);
    const [addLoading, setAddloading] = useState(false);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showSpeciality, setSpecialityShow] = useState(false);


    //DATA POST METIER
    const [status, setStatus] = useState('');
    const [id_professionel, setIdpro] = useState('');
    const [ref_specialite, setRef_specialite] = useState('');
    const [ref_user, setRefuser] = useState('');


    const handleClickOpen = (val) => {
        setRefuser(val.idt_users);
        setOpen(true);
    };

    const handleClose = () => {
        setRefuser("");
        setOpen(false);
    };

    const handleChange = (event) => {
        setRef_specialite(event.target.value);
    };

    const showModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }

    const showSpecilityModal = (val) => {
        setRefuser(val.idt_users);
        setSpecialityShow(true)
    }

    const showDemandeModal = (val) => {
        setRefuser("");
        setRefuser(val.idt_users);
        setShow(true)
    }

    const closeSpecilityModal = () => {
        setRefuser("");
        setSpecialityShow(false)
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };

    const getExperts = () => {
        axios.get("/accounts/users/experts", { headers: authHeader() })
        .then(res => 
        {
        
            if (res.data)
            {
                setLoading(false);
                setExperts(res.data);
            }
        }).catch(error => {
            console.log(error);
            setError(true);
        })
    }

    const getSpecialite = () => {
        axios.get("/specialites/all", { headers: authHeader() })
        .then(res => 
        {
            if (res.data)
            {
                setSpecialites(res.data);
                setSpeloading(false);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const submitData = () => {
   
        if (ref_user !== "")
        {

            setAddloading(true);
            const data = {
                ref_specialite: ref_specialite,
                status: status,
                id_professionel: id_professionel,
                ref_user: ref_user
            }; 
            axios.post(`/accounts/experts/add`, data, {headers: authHeader()})
                .then(function (json) {
                    if(json.data.success === true){
                        setAddloading(false);
                        handleClose();
                        swal({ title: "Succès", icon: 'success', text: `Spécialité ajoutée avec succès.` });
                    }else{
                        setAddloading(false);
                        swal({ title: "Avertissement", icon: 'warning', text: json.data.message });
                    }
                }).catch(function (error) {
                    swal({ title: "Erreur", icon: 'error', text: error });
            });  
        } else {
            alert('Merci de complèter tous les champs.')
        }
        
    }

    function approvalSubmit(id){
        swal({
            title: "Es-tu sûr ?",
            text: "Une fois verifié, cet expert sera visible sur la plateforme !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                const data = {
                    idt_users: id,
                    status: 'active'
                }; 
                axios.post(`/accounts/experts/approval`, data, {headers: authHeader()})
                    .then(function (json) {
                        if(json.data.success === true){
                            swal("Merci ! L'expert a été verifié avec succès !", {
                                icon: "success",
                            });
                            getExperts();
                        }else{
                            swal({ title: "Avertissement", icon: 'warning', text: "Nous n'avons pas pu verifé cet expert. Veuillez réessayer plutard !" });
                        }
                }).catch(function (error) {
                    swal({ title: "Erreur", icon: 'error', text: error });
                });  
            } else {
              swal("Suppression rejétée.");
            }
        });
    }


    useEffect(() => {
        getExperts();
        getSpecialite();
    }, [])



    return(
        <div>
                
                <HeaderApp/>
                <div className="clearfix"></div>
                <div className="dashboard-container">
                <SideBar
                    page={"manage"}
                />
                 
                    <div className="dashboard-content-container" data-simplebar>
                        <div className="dashboard-content-inner" >

                            <div className="dashboard-headline">
                                <h3>Manage Experts</h3>
                                <span className="margin-top-7">Gestion des Experts</span>
                                <nav id="breadcrumbs" className="dark">
                                    <ul>
                                        <li><a href="#">Dashboard</a></li>
                                        <li><a href="#">Management</a></li>
                                        <li>Manage Experts</li>
                                    </ul>
                                </nav>
                            </div>
                    
                           
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="dashboard-box margin-top-0">

                                        <div className="headline">
                                            <h3>
                                            <i className="icon-material-outline-supervisor-account"></i> 
                                            {
                                              experts.data && experts.data.length > 0 ? experts.data.length  : '0'
                                            } Experts
                                            </h3>
                                        </div>

                                        {  loading  ? <div className="center">
                                            <Skeleton
                                             sx={{ bgcolor: 'grey.900' }}
                                             variant="rectangular"
                                             height={118}
                                        /></div> : 
                                        <div className="col-xl-12 col-md-12">
                                    
                                            <table className="basic-table">
                                                
                                                <tr>
                                                    <th>Nom</th>
                                                    <th>E-mail</th>
                                                    <th>Téléphone</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {experts.data.map(item => (

                                                    <tr key={item.idt_users}>
                                                        <td data-label={item.noms}>{item.noms}</td>
                                                        <td data-label={item.email}>{item.email}</td>
                                                        <td data-label={item.phone}>{item.phone}</td>
                                                        <td>
                                                           
                                                           
                                                            {

                                                                item.status === false ? <button onClick={(e) => approvalSubmit(item.idt_users)} className="button green ripple-effect"><i className="icon-material-outline-check-circle"></i>Vérifier</button> : 
                                                                <div><button onClick={(e) => handleClickOpen(item)} className="button ripple-effect"><i className="icon-feather-plus"></i>Ajout Spécialité</button>
                                                                <button onClick={(e) => showSpecilityModal(item)} className="button red ripple-effect"><i className="icon-material-outline-assignment"></i>Spécialité</button>
                                                                <button onClick={(e) => showDemandeModal(item)} className="popup-with-zoom-anim button dark ripple-effect"><i className="icon-feather-mail"></i>Demande</button></div>
                                                            }
                                                            
                                                        </td>
                                                    </tr>
                                                    ))
                                                }

                                            </table>
                                        </div>
                                        }
                                 
                                    </div>

                                </div>

                            </div>
                            <div className="dashboard-footer-spacer"></div>
                            <Footer/>
                        </div>
                    </div>

                </div>
    
                <Dialog open={open} onClose={handleClose}>
                
                    <DialogTitle>Ajout Spécialité</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            To subscribe to this website, please enter your email address here. We
                            will send updates occasionally.
                            Ajouter Métier
                        
                        </DialogContentText>

                        <Box sx={{ minWidth: 220 }}>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={handleChangeStatus}
                                    >
                                        <MenuItem value={"inactive"}>En attente</MenuItem>
                                        <MenuItem value={"active"}>Confirmer</MenuItem>
                                </Select>

                                <FormHelperText>Status</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">Spécialité</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={ref_specialite}
                                    label="Spécialité"
                                    onChange={handleChange}
                                    >
                                        { speLoading ? '': specialites.data.map(item => (
                                            <MenuItem key={item.idt_specialite} value={item.idt_specialite}>{item.specialite}</MenuItem>
                                            ))
                                        }
                                </Select>
                                <FormHelperText>Spécialité</FormHelperText>
                            </FormControl>
                                
                                <Box sx={{minHeight: 20}}>
                                </Box>
                            
                                
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="ID Profesionnel"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setIdpro(e.target.value)}
                                />
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        {
                            addLoading ? <CircularIndeterminate/> : <Button onClick={submitData}>Ajouter</Button>
                        }
                        
                    </DialogActions>

                </Dialog>

                {
                    ref_user === "" ? null :    
                    <Demande
                        open={show}
                        close={closeModal}
                        expert={ref_user}
                    />
                }

                

                {
                    ref_user === "" ? null :    
                    <MySpeciality
                        open={showSpeciality}
                        close={closeSpecilityModal}
                        expert={ref_user}
                    />
                }
             

            </div>
    )
}

export default ManageExpert