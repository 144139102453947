import Footer from "../../components/footer/footer";
import HeaderApp from "../../components/header/header";
import SideBar from "../../components/sidebar/sidebar";
import React, { useState, useEffect } from 'react';
import CircularIndeterminate from "../../components/circularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../services/api/axios/baseUrl";
import authHeader from "../../services/api/token";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import swal from "sweetalert";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';


function ListClient(){

    const [clients, setClients] = useState([]);
    const [specialites, setSpecialites] = useState([])
    const [loading, setLoading] = useState(true);
    const [speLoading, setSpeloading] = useState(true);
    const [addLoading, setAddloading] = useState(false);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showSpeciality, setSpecialityShow] = useState(false);
    const [page, setPage] = useState("");


    //DATA POST METIER
    const [status, setStatus] = useState('');
    const [id_professionel, setIdpro] = useState('');
    const [ref_specialite, setRef_specialite] = useState('');
    const [ref_user, setRefuser] = useState('');


    const handleClickOpen = (val) => {
        setRefuser(val.idt_users);
        setOpen(true);
    };

    const handleClose = () => {
        setRefuser("");
        setOpen(false);
    };

    const handleChange = (event) => {
        setRef_specialite(event.target.value);
    };

    const closeModal = () => {
        setShow(false)
    }


    const closeSpecilityModal = () => {
        setRefuser("");
        setSpecialityShow(false)
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };

    const getClients = () => {
        axios.get("/accounts/users/all", { headers: authHeader() })
        .then(res => 
        {
        
            if (res.data)
            {
                setLoading(false);
                setClients(res.data);
            }
        }).catch(error => {
            console.log(error);
            setError(true);
        })
    }

    const getSpecialite = () => {
        axios.get("/specialites/all", { headers: authHeader() })
        .then(res => 
        {
            if (res.data)
            {
                setSpecialites(res.data);
                setSpeloading(false);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const submitData = () => {
   
        if (ref_user !== "")
        {

            setAddloading(true);

            const _formData = new FormData();
            _formData.append("status", status);
            _formData.append("id_professionel", id_professionel);
            _formData.append("ref_specialite", ref_specialite);
            _formData.append("ref_user", ref_user);
            const requestOptions = {
                method: 'POST',
                body: _formData,
                headers: authHeader()
            };
                 
            try {

                fetch('https://bck-expert.e-solutions-rdc.com/api/v1/accounts/experts/add', requestOptions)
                .then(res => res.json())
                .then(json =>{

                    if(json.success == true){
                        setAddloading(false);
                        handleClose();
                        swal({ title: "Succès", icon: 'success', text: `Spécialité ajoutée avec succès.` });
                    }else{
                        setAddloading(false);
                        swal({ title: "Avertissement", icon: 'warning', text: json.message });
                    }

                });
                    
            } catch (err) {
                console.log(err);
                setAddloading(false);
                swal({ title: "Avertissement", icon: 'warning', text: "Erreur de connexion au serveur." });
            }
        } else {
            alert('Merci de complèter tous les champs.')
        }
        
    }


    useEffect(() => {
        getClients();
        getSpecialite();
        setPage("client");
    }, [])



    return(
        <div>
                
                <HeaderApp/>
                <div className="clearfix"></div>
                <div className="dashboard-container">
                <SideBar
                    page={page}
                />
                 
                    <div className="dashboard-content-container" data-simplebar>
                        <div className="dashboard-content-inner" >

                            <div className="dashboard-headline">
                                <h3>Liste Clients</h3>
                                <span className="margin-top-7">Gestion des clients</span>
                                <nav id="breadcrumbs" className="dark">
                                    <ul>
                                        <li><a href="#">Dashboard</a></li>
                                        <li><a href="#">Management</a></li>
                                        <li>Liste Clients</li>
                                    </ul>
                                </nav>
                            </div>
                    
                           
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="dashboard-box margin-top-0">

                                        <div className="headline">
                                            <h3>
                                            <i className="icon-material-outline-supervisor-account"></i> 
                                            {
                                              clients.data && clients.data.length > 0 ? clients.data.length  : '0'
                                            } Clients
                                            </h3>
                                        </div>

                                        {  loading  ? <div className="center">
                                            <Skeleton
                                             sx={{ bgcolor: 'grey.900' }}
                                             variant="rectangular"
                                             height={118}
                                        /></div> : 
                                        <div className="content">
                                            <ul className="dashboard-box-list">
                                            {clients.data.map(item => (
                                                
                                                <li key={item.idt_users}>
                                                    <div className="freelancer-overview manage-candidates">
                                                        <div className="freelancer-overview-inner">

                                                            <div className="freelancer-avatar">
                                                                <div className="verified-badge"></div>
                                                                <a href="#"><img src="images/user-avatar-placeholder.png" alt=""/></a>
                                                            </div>

                                                            <div className="freelancer-name">
                                                                <h4><a href="#">{item.noms} <img className="flag" src="images/flags/au.svg" alt="" title="Australia" data-tippy-placement="top"/></a></h4>

                                                                <span className="freelancer-detail-item"><a href="#"><i className="icon-feather-mail"></i> {item.email}</a></span>
                                                                <span className="freelancer-detail-item"><i className="icon-feather-phone"></i> {item.phone}</span>
                                                                <div className="freelancer-rating">
                                                                    <div className="star-rating" data-rating="5.0"></div>
                                                                </div>

                                                                <div className="buttons-to-right always-visible margin-top-25 margin-bottom-5">
                                                                    <button onClick={(e) => handleClickOpen(item)} className="button ripple-effect"><i className="icon-feather-plus"></i>Ajout Tâche</button>
                                                                    <button href="#" className="button gray ripple-effect ico" title="Remove Candidate" data-tippy-placement="top"><i className="icon-feather-trash-2"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))}

                                            </ul>
                                        </div>
                                        }
                                 
                                    </div>

                                </div>

                            </div>
                            <div className="dashboard-footer-spacer"></div>
                            <Footer/>
                        </div>
                    </div>

                </div>
    
                <Dialog open={open} onClose={handleClose}>
                
                    <DialogTitle>Ajout Spécialité</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            To subscribe to this website, please enter your email address here. We
                            will send updates occasionally.
                            Ajouter Métier
                        
                        </DialogContentText>

                        <Box sx={{ minWidth: 220 }}>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={handleChangeStatus}
                                    >
                                        <MenuItem value={"inactive"}>En attente</MenuItem>
                                        <MenuItem value={"active"}>Confirmer</MenuItem>
                                </Select>

                                <FormHelperText>Status</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">Spécialité</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={ref_specialite}
                                    label="Spécialité"
                                    onChange={handleChange}
                                    >
                                        { speLoading ? '': specialites.data.map(item => (
                                            <MenuItem key={item.idt_specialite} value={item.idt_specialite}>{item.specialite}</MenuItem>
                                            ))
                                        }
                                </Select>
                                <FormHelperText>Spécialité</FormHelperText>
                            </FormControl>
                                
                                <Box sx={{minHeight: 20}}>
                                </Box>
                            
                                
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="ID Profesionnel"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setIdpro(e.target.value)}
                                />
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        {
                            addLoading ? <CircularIndeterminate/> : <Button onClick={submitData}>Ajouter</Button>
                        }
                        
                    </DialogActions>

                </Dialog>

            </div>
    )
}

export default ListClient